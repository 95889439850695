//------------------------------------------------------------------------------
// BASE_INCLUDE.SCSS ***********************************************************
// This file is used to theme all basic markup that belongs to the content
//------------------------------------------------------------------------------

//--------------------
//-- IE8 BORDER BOX --
//--------------------
.ie8 {
  *, *:after, *:before {
    box-sizing: inherit;
  }
}

//----------
//-- BODY --
//----------
body {
  -webkit-tap-highlight-color: rgba(red($highlight), green($highlight), blue($highlight), 0.5);
  -webkit-text-size-adjust: 100%; // Prevents automatic text resize on screen resize
  font-family: $base-font-family;
  font-size: $font-size;
  line-height: $line-height;
  color: $body;
}

//--------------
//-- HEADINGS --
//--------------
h1,
h2,
h3 {
  @include hyphenate();
}

h1 {
  @include font(24);
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: rhythm(0.5);
}

h2 {
  @include font(20);
  font-weight: 700;
  margin-bottom: rhythm(0.25);
}

h3 {
  @include font(18);
  margin-bottom: rhythm(0.25);
  font-weight: 700;
}

p.title {
  @extend h3;
}

h4,
h5,
h6 {
  margin-bottom: rhythm(0.25);
  font-weight: bold;
}

//----------
//-- TEXT --
//----------
p {
  font-size: em($base-font-size);
  margin-bottom: rhythm(0.75);
}

p.clear {
  clear: both;
}

p.intro {
  font-weight: 700;
}

#content > article {
  width: 100%; //IE 10
}

blockquote {
  padding: rhythm(0.5) rhythm(0.75);
  p {
    margin-bottom: 0;
  }
}

b, strong, caption, th, thead, dt, legend {
  font-weight: bold;
}

cite, dfn, em, i {
  font-style: italic;
  font-weight: normal;
}

abbr, acronym {
  text-transform: uppercase;
}

sup, sub {
  line-height: 0;
}

sup {
  vertical-align: super;
}

sub {
  vertical-align: sub;
}

code, kbd, samp, pre, tt, var {
  font-family: mono-space, monospace;
}

//-----------
//-- LINKS --
//-----------
a {
  color: $blue-new;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $black;
  }
}

//-------------
//-- BUTTONS --
//-------------

// General
.btn {
  display: inline-block;
  border: 1px solid transparent;
  padding: rhythm(0.15) rhythm(0.5);
  text-decoration: none;
  text-transform: uppercase;
  @include transition(all 0.2s ease-in-out);
}
// Button default
.btn--default {
  color: $white;
  background: $blue;
  border-color: $blue;

  &:hover {
    background: $blue-light;
    border-color: $blue-light;
  }
}
// Button more
.btn--more {
  color: $white;
  background: $blue;
  border-color: $blue;

  &:hover {
    background: $blue-light;
    border-color: $blue-light;
  }
  &:after {
    content: " \003E";
    margin-left: rhythm(0.5);
  }
}
// Button link
.btn--link {
  color: $body;
  background: transparent;
  padding: 0;
  text-decoration: underline;
  text-transform: none;
}
// Offer
.btn--offer {
  background: $gold;
  margin: em(30px) 0;
  color: $white;
  text-align: center;
  &:hover {
    background: $brown;
    color: $white;
  }
}

//----------
//-- IMGS --
//----------
img {
  height: auto;
  max-width: 100%;
}

.ie8 img {
  width: auto;
}

img.left {
  float: left;
  margin: 0 rhythm(0.5) rhythm(0.5) 0;
}

img.right {
  float: right;
  margin: 0 0 rhythm(0.5) rhythm(0.5);
}

.rteright { text-align: right; }
.rtecenter { text-align: center; }
.rtejustify { text-align: justify; }

//----------------
//-- LIST ITEMS --
//----------------
// All list items in the content region should look "normal", e.a. not be reset
article,
body.cke_show_borders {
  ul,
  ol {
    padding-left: rhythm(1);
    margin-bottom: rhythm(0.75);
    overflow: hidden;
  }

  ul {
    list-style: square;
  }

  ol {
    list-style: decimal;
  }

  li.rtecenter {
    list-style-position: inside;
  }
}
