//--------------------------------------------------------------------------------
// BLOCKS.SCSS ********************************************************************
// This file is used to style all the blocks, coming from views. Also single blocks
// like search block, language switcher, social media,... are styled in this file.
//--------------------------------------------------------------------------------

//--------------
//-- LANGUAGE --
//--------------
.language {
  float: right;

  ul {
    float: right;
  }
  li {
    float: left;
  }
  a,
  span {
    padding: 0 rhythm(0.55);
    display: block;
    color: $white;
    @include font(13);
    line-height: 40px;
    text-decoration: none;
    @include transition(all 0.2s ease-in-out);
  }
  span {
    text-decoration: line-through;
  }
  a {
    &.active, &:hover {
      // background: $blue-light;
      background: $gold;
    }
  }
}

//-------------------
//-- STATIC BANNER --
//-------------------
.static-banner {
  float: left;
  width: 100%;
  position: relative;

  img {
    display: block;
    width: 100%;
  }
  .btn--default {
    color: $body;
    background: transparent;
    border-color: $body;
    margin-top: rhythm(0.25);
  }
  .container {
    max-width: 100%;
    padding: 0;
  }
}
.banner__text {
  background: $blue-new;
  float: left;
  width: 100%;
  // background: $grey-medium;
  padding: em(30px);
  color: $white;
  text-align: right;

  p {
    margin: 0;
  }
  p.text {
    margin-bottom: rhythm(0.25);
  }
  p.title, p.text {
    font-family: $playfair;
    @include font(18);
  }
  p.btn--default {
    color: $white;
    border-color: $white;
  }
}

//-----------
//-- CTA'S --
//-----------
.cta,
.usp {
  width: 100%;
  float: left;
  color: $white;
  text-align: center;
  margin-bottom: rhythm(0.5);
  padding: rhythm(1.5) rhythm(1.25);
  @include transition(all 0.2s ease-in-out);

  p.title {
    margin: 0;
    @include font(26);
  }
  p {
    margin: rhythm(0.5) 0 0;
  }
  .btn {
    background: transparent;
    border-color: $white;
  }
}
.cta {
  background: $gold;
}
.cta:hover {
  background: $brown;
}
.cta.light {
  background: $grey-light;
  color: $body;
}

//usp overview

.usp-overview {
  margin: rhythm(2) 0;
  text-align: center;
  float: left;
  width: 100%;

  .overview-usp__item {
    padding: 1em 2em;

    img {
      margin-bottom: 1em;
    }
  }
}

//-------------------
//-- TAB WITH LINK --
//-------------------
.block--has-link {
  padding: 0;

  > a {
    display: block;
    color: inherit;
    padding: rhythm(1.5) rhythm(1.25);
    text-decoration: none;
  }
}

//------------------
//-- CONTACT INFO --
//------------------
.contact-info {
  float: left;
  width: 100%;
  margin-top: rhythm(0.5);
}

//------------------
//-- CONTACT FORM --
//------------------
.contact {
  float: left;
  width: 100%;
  margin-top: rhythm(0.5);
}

//-----------------
//-- GOOGLE MAPS --
//-----------------
.google-map {
  float: left;
  width: 100%;
  margin: rhythm(1) 0 rhythm(0.75);

  #map-canvas {
    height: 350px;
  }
  #info-widget {
    @include rgba-bg($white, 0.8);
    padding: rhythm(0.5);
    margin: rhythm(0.5);
  }
}
.gmnoprint img {
  max-width: none;
}

//------------------
//-- SOCIAL MEDIA --
//------------------
.social {
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: rhythm(0.25);

  > a {
    text-indent: -9999px;
    display: inline-block;
    margin: 0 em(10px);

    &:hover {
      @include opacity(0.75);
    }
  }

  .facebook {
    @include icon(facebook);
  }
  .googleplus {
    @include icon(googleplus);
  }
  .linkedin {
    @include icon(linkedin);
  }
  .pinterest {
    @include icon(pinterest);
  }
  .twitter {
    @include icon(twitter);
  }
  .vimeo {
    @include icon(vimeo);
  }
  .youtube {
    @include icon(youtube);
  }
}

//-------------------
//-- ADDRESS BLOCK --
//-------------------
.address {
  float: left;
  width: 100%;
  text-align: center;
  color: $white;
  font-weight: 300;
  @include font(14, 1.2);

  p {
    margin: 0;

    a {
      color: $white;
    }
  }
}

//----------------
//-- NEWSLETTER --
//----------------
.newsletter-form {
  float: left;
  width: 100%;
  margin-bottom: rhythm(1);

  p.title {
    text-align: center;
    font-weight: normal;
    color: $white;
    margin-bottom: rhythm(0.4);
  }
  form > * {
    margin: 0;
  }
  .e_mail {
    position: relative;
    overflow: hidden;
  }
  label {
    color: $blue;
    position: absolute;
    padding-left: rhythm(0.25);
    @include transition(margin 0.2s ease-in-out);
  }
  input[type="email"] {
    border-color: transparent;
    padding-left: rhythm(4.5);
    @include transition(padding 0.2s ease-in-out);

    &:focus {
      border-color: $blue;
    }
  }
  input[type="submit"] {
    &:hover {
      background: lighten($blue, 5%);
    }
  }
  .filled {
    label {
      margin-left: -100%;
    }
    input[type="email"] {
      padding-left: rhythm(0.25);
    }
  }
}

//------------------
//-- COMPANY LOGO --
//------------------
.the-aim-company-logo {
  float: left;
  margin-top: rhythm(1.25);
  text-indent: -9999px;
  @include icon(theaim);

  &:hover {
    @include opacity(0.75);
  }
}

//--------------------
//-- FRONT PRODUCTS --
//--------------------
.overview__front-products {
  width: 100%;
  float: left;
  margin: em(30px) 0 0;
}
.overview__front-products--item {
  width: 100%;
  float: left;
  margin: 0 0 em(30px);
  overflow: hidden;
  position: relative;
  &:hover {
    img {
      @include transform(scale(1.1,1.1));
    }
    .overview__front-products--text {
      // background: rgba(219,127,8, 0.7);
      background: rgba(155,147,64,0.8);
      padding: em(200px) 0;
      opacity: 1;
    }
  }
}
.overview__front-products--link {
  display: block;
  color: $white;
  text-decoration: none;
  &:hover {
    color: $white;
  }
  img {
    display: block;
    @include transition(all 0.2s linear);
  }
}
.overview__front-products--text {
  background: rgba(0,0,0,0.6);
  padding: em(20px) 0;
  opacity: 0.5;
  text-align: center;
  @include transition(all 0.2s ease-in-out);
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  @include transform(translateY(-50%));
  z-index: 1;
  p {
    margin: 0;
  }
  .title {
    margin-bottom: em(5px);
    font-family: $playfair;
    font-size: em(30px);
    font-weight: 400;
  }
  .more {
    display: inline-block;
    padding: em(5px) em(20px);
    border: 1px solid $white;
    text-transform: uppercase;
  }
}

//*************************
//***** MEDIA QUERIES *****
//*************************

//-------------
//-- BREAK 1 --
//-------------
@include media($break-1) {

  // Front products
  .overview__front-products--item {
    @include span-columns(3);
    &.even {
      @include omega;
    }
  }

  // Newsletter
  .newsletter-form {

    .e_mail {
      width: 75%;
      margin: 0;
    }
    .form-actions {
      width: 25%;
    }
    input[type="submit"] {
      width: 100%;
      line-height: 50px;
      padding: 0 rhythm(0.5);
    }
  }

}//End break-1

//-------------
//-- BREAK 2 --
//-------------
@include media($break-2) {

  // Languages
  .language {
    margin-left: rhythm(1.5)
  }

  // Static banner
  .static-banner {
    .container {
      max-width: em($break-4-w);
    }
  }
  .banner__text {
    background: rgba(0,0,0,0.3);
    position: absolute;
    width: 65%;
    bottom: 0;
    right: 0;

    p.title, p.text {
      @include font(34, 0.9);
    }
  }

  //usp overview

  .usp-overview {
    margin: 0;
    padding: 0 rhythm(3);
    text-align: center;

    .overview-usp__item {
      float: left;
      width: 33.33%;
    }
  }

  // Front products
  .overview__front-products--item {
    &.odd,
    &.even {
      @include span-columns(2.25);
    }
    &.third + div {
      @include omega;
    }
  }

  // Cta's
  .cta {
    padding: rhythm(1.5) rhythm(1);
    margin-bottom: rhythm(1);
  }

  // TAB with link
  .block--has-link {
    padding: 0;

    > a {
      padding: rhythm(1.5) rhythm(1);
    }
  }

  // Newsletter
  .newsletter-form {
    @include span-columns(5.5);
    margin-bottom: 0;

    p.title {
      text-align: left;
    }
    .e_mail {
      width: 70%
    }
    .form-actions {
      width: auto;
    }
  }

  // Contact info
  .contact-info {
    @include span-columns(3 of 6);
    margin-top: rhythm(0.75);
  }

  // Contact form
  .contact {
    @include span-columns(3 omega of 6);
    margin-top: rhythm(0.75);
  }

  // User login
  #user-login {
    @include span-columns(7);
  }

  // Social media
  // .social {
  //   @include span-columns(3.5 omega);
  //   text-align: right;
  //   margin-top: rhythm(1.7);

  //   a {
  //     text-indent: 9999px;
  //   }
  // }
  .social {
    > a {
      margin: 0 em(40px);
    }
  }

  .address {
    max-width: 530px;
    text-align: left;
  }

}//End break-2

//-------------
//-- BREAK 3 --
//-------------
@include media($break-3) {

  // Static banner
  .static-banner {
    .container {
      max-width: em($break-4-w);
    }
  }
  .banner__text {
    width: 50%;
  }

  // Cta's
  .cta {
    padding: rhythm(1.5) rhythm(1.25);
  }

  // TAB with link
  .block--has-link {
    padding: 0;

    > a {
      padding: rhythm(1.5) rhythm(1.25);
    }
  }

  // Newsletter
  .newsletter-form {
    @include span-columns(8);

    p.title {
      float: left;
      line-height: 50px;
      margin: 0 rhythm(0.6) 0 0;
    }
    form {
      width: 65%;
    }
    .e_mail {
      width: 67%;
    }
  }

  // Contact info
  .contact-info {
    @include span-columns(3.5 of 8);
  }

  // Contact form
  .contact {
    @include span-columns(4.5 omega of 8);
  }

  // Social media
  // .social {
  //   @include span-columns(4 omega);
  //   margin-top: rhythm(0.4);
  // }

  // User login
  #user-login {
    @include span-columns(8);
  }

}//End break-3
