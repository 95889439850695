$white: #FFFFFF;
$black: #000000;
$grey-light: #F2F2F2;
$grey-medium: #E9E9E9;
$grey: #8E8E8E;
$grey-dark: #3F3F3F;
$body: #111111;
$highlight: #094EA9;
$link: #337AB7;
$blue-light: #1FA3C9;
$blue: #002D56;
$blue-dark: #001f3b;
$gold: #9b9340;
$blue-new: #0f4061;
$brown: #4d4736;
