//------------------------------------------------------------------------------
// FORMS_INCLUDE.SCSS **********************************************************
// This file is used to theme all form elements
//------------------------------------------------------------------------------

// Some reset that isn't in Eric Meyer's reset.css
button,
input[type="button"] {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

*:focus {
  outline: none; // Prevent yellow outline in Chrome
}

input, textarea {
  border-radius: 0; // Prevent rounded corners on iPhone
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="submit"],
textarea {
  -webkit-appearance: none; //Prevent rounded corners and shadow on IOS
  font-family: $base-font-family;
  @include font($base-font-size);
}

input,
textarea {
  @include selection;
}

form > * {
  width: 100%;
  float: left;
  margin-bottom: rhythm(0.25);
}

fieldset > * {
  width: 100%;
  float: left;
}

form,
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
select {
  width: 100%;
  float: left;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea,
select {
  border: 1px solid $blue;

  &:focus {
    outline: 1px solid $highlight;
  }
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="number"] {
  padding: 0 rhythm(0.25);
}

.ie8 {
  input[type="text"],
  input[type="tel"],
  input[type="password"],
  input[type="email"],
  input[type="number"] {
    line-height: 50px;
  }
}

textarea {
  padding: rhythm(0.25);
  resize: vertical;
  height: 120px;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="number"],
label,
legend {
  height: 50px;
}

select {
  padding: rhythm(0.25);
}

label {
  line-height: 50px;
}

input[type="checkbox"],
input[type="radio"] {
  border: 0;
  float: left;
  margin: 0;
  padding: 0;
}

input[type="submit"] {
  float: left;
  width: 100%;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: rhythm(0.45) rhythm(0.75);
  color: $white;
  background: $blue;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  @include transition(all 0.2s ease-in-out);

  &:hover {
    background: $blue-light;
  }
}

label,
legend,
small {
  width: 100%;
  float: left;
}

label.required:after {
  content: " *";
}

small {
  @include font(11);
  margin-top: rhythm(0.25);
}

.form-radios,
.form-checkboxes {
  width: 100%;
  float: left;
  > div {
    width: 100%;
    float: left;
    margin-bottom: rhythm(0.25);
  }
  label {
    width: auto;
    float: left;
    margin: 0;
    padding: 0 0 0 rhythm(0.25);
  }
  input,
  label {
    height: 20px;
    line-height: 20px;
  }
}

.form-actions {
  margin-top: rhythm(0.25);
}

// Honeypot blank field
.url-textfield {
  display: none;
}

//*************************
//***** MEDIA QUERIES *****
//*************************

//-------------------
//-- BREAK 1 --------
//-------------------
@include media($break-1) {

  input[type="submit"] {
    float: right;
    width: auto;
  }
  
}//End break-1
