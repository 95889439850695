%icon {
  background-image: url(../img/spritesheet.png);
  background-repeat: no-repeat;

  html.svg & {
    background-image: url(../img/spritesheet.svg);
  }
}

%icon-btn-arrow-down {
  @extend %icon;
  background-position: 0 0;
}

%icon-btn-arrow-down-dims {
  width: 26px;
  height: 26px;
}

%icon-btn-arrow-left {
  @extend %icon;
  background-position: 0 -26px;
}

%icon-btn-arrow-left-dims {
  width: 26px;
  height: 26px;
}

%icon-btn-arrow-right {
  @extend %icon;
  background-position: 0 -52px;
}

%icon-btn-arrow-right-dims {
  width: 26px;
  height: 26px;
}

%icon-btn-arrow-up {
  @extend %icon;
  background-position: 0 -78px;
}

%icon-btn-arrow-up-dims {
  width: 26px;
  height: 26px;
}

%icon-btn-close {
  @extend %icon;
  background-position: 0 -104px;
}

%icon-btn-close-dims {
  width: 26px;
  height: 26px;
}

%icon-btn-menu {
  @extend %icon;
  background-position: 0 -130px;
}

%icon-btn-menu-dims {
  width: 33px;
  height: 25px;
}

%icon-btn-menu-close {
  @extend %icon;
  background-position: 0 -155px;
}

%icon-btn-menu-close-dims {
  width: 33px;
  height: 25px;
}

%icon-cb-close {
  @extend %icon;
  background-position: 0 -180px;
}

%icon-cb-close-dims {
  width: 20px;
  height: 20px;
}

%icon-cb-next {
  @extend %icon;
  background-position: 0 -200px;
}

%icon-cb-next-dims {
  width: 19.1px;
  height: 36.7px;
}

%icon-cb-prev {
  @extend %icon;
  background-position: 0 -237px;
}

%icon-cb-prev-dims {
  width: 19.1px;
  height: 36.7px;
}

%icon-facebook {
  @extend %icon;
  background-position: 0 -274px;
}

%icon-facebook-dims {
  width: 20.3px;
  height: 31px;
}

%icon-facebook-share {
  @extend %icon;
  background-position: 0 -305px;
}

%icon-facebook-share-dims {
  width: 20.3px;
  height: 31px;
}

%icon-googleplus {
  @extend %icon;
  background-position: 0 -336px;
}

%icon-googleplus-dims {
  width: 37.7px;
  height: 31px;
}

%icon-googleplus-share {
  @extend %icon;
  background-position: 0 -367px;
}

%icon-googleplus-share-dims {
  width: 37.7px;
  height: 31px;
}

%icon-icon-menu {
  @extend %icon;
  background-position: 0 -398px;
}

%icon-icon-menu-dims {
  width: 25px;
  height: 25px;
}

%icon-icon-menu-close {
  @extend %icon;
  background-position: 0 -423px;
}

%icon-icon-menu-close-dims {
  width: 25px;
  height: 25px;
}

%icon-linkedin {
  @extend %icon;
  background-position: 0 -448px;
}

%icon-linkedin-dims {
  width: 37.1px;
  height: 31px;
}

%icon-linkedin-share {
  @extend %icon;
  background-position: 0 -479px;
}

%icon-linkedin-share-dims {
  width: 37.1px;
  height: 31px;
}

%icon-pinterest {
  @extend %icon;
  background-position: 0 -510px;
}

%icon-pinterest-dims {
  width: 30.1px;
  height: 31px;
}

%icon-pinterest-share {
  @extend %icon;
  background-position: 0 -541px;
}

%icon-pinterest-share-dims {
  width: 30.1px;
  height: 31px;
}

%icon-theaim {
  @extend %icon;
  background-position: 0 -572px;
}

%icon-theaim-dims {
  width: 80px;
  height: 17px;
}

%icon-twitter {
  @extend %icon;
  background-position: 0 -589px;
}

%icon-twitter-dims {
  width: 47.6px;
  height: 31px;
}

%icon-twitter-share {
  @extend %icon;
  background-position: 0 -620px;
}

%icon-twitter-share-dims {
  width: 47.6px;
  height: 31px;
}

%icon-vimeo {
  @extend %icon;
  background-position: 0 -651px;
}

%icon-vimeo-dims {
  width: 42.1px;
  height: 31px;
}

%icon-youtube {
  @extend %icon;
  background-position: 0 -682px;
}

%icon-youtube-dims {
  width: 32.9px;
  height: 31px;
}

