//--------------------------------------------------------------------------------
// ADMIN.SCSS ********************************************************************
// Put all the admin styling (tabs to edit content, ...) in here.
//--------------------------------------------------------------------------------

.element-invisible { @extend %element-invisible; }

html body.admin-menu {
  margin-top: 0!important;
}
#admin-menu {
  display: none;
}

//------------------
//-- TABS ----------
//------------------
ul.primary {
  width: 100%;
  float: left;
  margin: rhythm(0.5) 0;

  li {
    display: block;
    border-bottom: 1px solid transparent;

    a {
      background: $grey;
      padding: em(5px) em(10px);
      text-decoration: none;
      color: $white;
      display: block;
      &:hover {
        background: lighten($grey, 10%);
      }
      &.active {
        background: darken($grey, 20%);
      }
    }
  }
}
li.krumo-child {
  float: none;
}

//------------------
//-- MESSAGES ------
//------------------
.messages {
  width: 100%;
  float: left;
  margin: rhythm(1) 0 0;
  padding: rhythm(0.5);
  border: 1px solid #be7;
  color: #234600;
  background: #f8fff0;

  &.warning {
    color: #840;
    border-color: #ed5;
    background: #fffce5;
  }
  &.error {
    color: #8c2e0b;
    border-color: #ed541d;
    background: #fef5f1;
  }
}

//------------------------
//-- CONTEXTUAL FILTERS---
//-- copied from core/modules/contextual and adjusted where needed
//-- We strip the original css because it's not sufficient and gets overwritten with theme css
//------------------------

// Contextual links regions
.contextual-links-region {
  outline: none;
  position: relative;
  overflow: visible;
}
.contextual-links-region-active {
  outline: #999 dashed 1px;
}

// Contextual links
div.contextual-links-wrapper {
  display: none;
  font-size: 90%;
  position: absolute;
  right: 5px; /* LTR */
  top: 2px;
  z-index: 999;
  border: 0;
  margin-top: rhythm(0);
  margin-bottom: rhythm(0);
  padding: 0;
}
html.js div.contextual-links-wrapper {
  display: block;
}
a.contextual-links-trigger {
  background: transparent url(../img/gear-select.png) no-repeat 2px 0 !important;
  border: 1px solid transparent;
  display: none;
  height: 18px;
  margin: 0;
  padding: 0 2px;
  outline: none;
  text-indent: 34px; /* LTR */
  width: 28px;
  overflow: hidden;
  border-radius: 4px;
}
a.contextual-links-trigger:before {
  content: "";
  display: inline;
  background: transparent;
}
a.contextual-links-trigger:after {
  content: "";
  display: inline;
  background: transparent;
}
a.contextual-links-trigger:hover,
div.contextual-links-active a.contextual-links-trigger {
  background-position: 2px -18px;
  background: transparent url(../img/gear-select.png) no-repeat 2px 0 !important;
}
div.contextual-links-active a.contextual-links-trigger {
  background-color: #fff;
  border-color: #ccc;
  border-bottom: none;
  position: relative;
  z-index: 1;
  border-radius: 4px 4px 0 0;
}
div.contextual-links-wrapper ul.contextual-links {
  width: auto !important;
  float: none !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  display: none !important;
  margin: 0 !important;
  padding: 0.25em 0 !important;
  position: absolute !important;
  right: 0 !important;
  text-align: left !important;
  top: 18px !important;
  white-space: nowrap !important;
  z-index: 5 !important;
  border-radius: 4px 0 4px 4px !important;
  max-height: none !important;
}
.contextual-links-region:hover a.contextual-links-trigger,
div.contextual-links-active a.contextual-links-trigger,
div.contextual-links-active ul.contextual-links {
  display: block !important;
}
ul.contextual-links li {
  text-align: left !important;
  line-height: 100% !important;
  list-style: none !important;
  list-style-image: none !important;
  margin: 0 !important;
  padding: 0 !important;
  float: none !important;
  display: inline !important;
}
div.contextual-links-wrapper a {
  text-decoration: none;
}
ul.contextual-links li a {
  color: #333 !important;
  background: transparent !important;
  display: block !important;
  margin: 0.25em 0 !important;
  padding: 0.25em 1em 0.25em 0.5em !important;
  text-indent: 0 !important;
  border: 0 !important;
  min-height: inherit !important;
  line-height: inherit !important
}
ul.contextual-links li a:before {
  content: "";
  display: inline !important;
  background: transparent !important;
}
ul.contextual-links li a:after {
  content: "";
  display: inline !important;
  background: transparent !important;
}
ul.contextual-links li a:hover {
  background-color: #bfdcee !important;
  text-decoration: none !important;
}

//------------------
//-- USER BLOCKS ---
//------------------
.block__user {
  float: left;
  width: 100%;
  border: 1px solid $grey-light;
  margin-top: rhythm(0.75);

  p.title {
    background: $grey-medium;
    padding: rhythm(0.25);
    margin: 0;
  }
  a {
    color: $body;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
.block__user--menu {
  ul {
    padding: 0 rhythm(0.5) rhythm(0.25);
  }
  > ul {
    padding-top: rhythm(0.25);
  }
  a {
    position: relative;
    padding-left: rhythm(0.4);

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 0;
      top: 50%;
      margin-top: -4px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid $black;
    }
  }
  ul ul a:before {
    border-left: 5px solid $grey;
  }
}
.block__user--content {
  table {
    width: 100%;
  }
  tr.even {
    background: $grey-light;
  }
  td {
    padding: rhythm(0.25);
  }
  .marker {
    color: red;
    @include font(13);
  }
  .node-author {
    display: none;
  }
  .more-link {
    padding: rhythm(0.25);
    margin-top: rhythm(0.25);

    a {
      text-decoration: underline;
    }
  }
}

//-------------------
//-- BREAK 1 --------
//-------------------
@include media($break-1) {

  // TABS
  ul.primary {
    li {
      float: left;
      border-right: 1px solid transparent;
    }
  }
}//End break-1

//-------------------
//-- BREAK 2 --------
//-------------------
@include media($break-2) {

  html body.admin-menu {
    margin-top: 29px!important;
  }
  #admin-menu {
    display: block;
  }

  // TABS
  // ul.primary {
  //   border-bottom: 1px solid $grey;
  //   list-style: none;
  //   padding-left: rhythm(1);
  //   white-space: nowrap;

  //   li {
  //     display: inline;

  //     a {
  //       padding: 0 rhythm(1);
  //       display: inline-block;
  //     }
  //   }
  // }

  // MESSAGES
  .messages {
    margin: 0 0 rhythm(1);
  }

  // USER BLOCKS
  .block__user--menu {
    @include span-columns(3.5 omega);
  }
  .block__user--content {
    @include span-columns(5.5);
  }
}//End break-2

//-------------------
//-- BREAK 3 --------
//-------------------
@include media($break-3) {

  // USER BLOCKS
  .block__user--menu {
    @include span-columns(4 omega);
  }
  .block__user--content {
    @include span-columns(8);
  }
}//End break-3
