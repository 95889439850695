//--------------------------------------------------------------------------------
// STRUCTURE.SCSS ****************************************************************
// This file contains the structure of the website. It controls the position and
// size of the main blocks (#content, #sidebar).
//--------------------------------------------------------------------------------

//--------------
//-- THROBBER --
//--------------
.ajax-progress-throbber {
  display: none;
}

//----------
//-- LOGO --
//----------
#logo {
  // float: left;
  // height: 90px;
  display: block;
  width: 200px;
  margin: em(10px) auto;

  img {
    display: block;
    // max-height: 80px;
    // position: relative;
    // top: 50%;
    // @include transform(translateY(-50%));
  }
}

//----------------
//-- CONTAINERS --
//----------------
// Body
body {
  &:after {
    content: "default";
    display: block;
    height: 0;
    overflow: hidden;
  }
}
// Main wrappers
.wrapper {
  overflow: hidden;
}
.container {
  @include outer-container;
  padding: 0 $gutter/2;
  position: relative;
}

//-------------
//-- REGIONS --
//-------------
// General
#top,
.wrapper > header,
#slider,
#main,
#content,
#sidebar-left,
#sidebar-right,
#content-bottom,
.wrapper > footer,
#bottom {
  width: 100%;
  float: left;
}

// Top
#top {
  // background: $blue;
  background: $brown;
}

// Header
.wrapper > header {
}

// Slider
#slider {
  position: relative;
}

// Main
#main {
  margin-bottom: rhythm(0.8);

  .front & {
    // background: $blue;
    // color: $white;
    margin-bottom: 0;

    a {
      // color: $white;
    }
  }
}

// Content
#content {
  margin: rhythm(0.75) 0 rhythm(0.6);

  .front & {
    margin: rhythm(1) 0 rhythm(1.5);
  }
}

// Footer
.wrapper > footer {
  // background: $blue-light;
  background: $brown;
  padding: em(30px) 0;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}

// Bottom
#bottom {
  // background: $blue-dark;
  background: $brown;
  padding: rhythm(1.25) 0;
  .container:before {
    display: block;
    content: url(../img/iso-big.png);
    margin: 0 auto em(30px);
    text-align: center;
  }
}

//*************************
//***** MEDIA QUERIES *****
//*************************

//-------------
//-- BREAK 1 --
//-------------
@include media($break-1) {

  @if $fixed-container {
    .container { max-width: em($break-1-w); }
    .ie8 .container { width: em($break-1-w); }
    body:before { max-width: em($break-1-w); }
  }

  // Body
  body {
    &:after {
      content: "break-1";
    }
  }

}//End break-1

//-------------
//-- BREAK 2 --
//-------------
@include media($break-2) {

  @if $fixed-container {
    .container { max-width: em($break-2-w); }
    .ie8 .container { width: em($break-2-w); }
    body:before { max-width: em($break-2-w); }
  }

  // Logo
  #logo {
    width: 35%;
  }

  // Body
  body {
    &:after {
      content: "break-2";
    }
  }

  // Telephone numbers
  a[href^=tel], a[href^=fax] {
    color: $black;
    cursor: text;
    text-decoration: none;
  }

  // Header
  .wrapper > header {
    margin-top: em(30);
  }
  .maintenance-page .wrapper > header {
    margin-bottom: rhythm(0.5);
  }

  // Main
  #main {
    margin-bottom: rhythm(1.5);

    .front & {
      padding: rhythm(1) 0 rhythm(1.5);
    }
  }

  // Content
  #content {
    margin: 0;

    .front & {
      margin: 0;
    }
  }
  body.no-left-sidebar.no-right-sidebar #content {
    width: 100%;
    float: left;
  }
  body.no-left-sidebar #content {
    @include span-columns(6);
  }
  body.no-right-sidebar #content {
    @include span-columns(6 omega);
  }

  // Sidebars
  #sidebar-left {
    @include span-columns(3);
  }
  #sidebar-right {
    @include span-columns(3 omega);
  }

  // Bottom
  #bottom {
    padding: rhythm(1.5) 0 rhythm(1);
    .container:before {
      width: auto;
      float: right;
    }
  }

}//End break-2

//-------------
//-- BREAK 3 --
//-------------
@include media($break-3) {

  @if $fixed-container {
    .container { max-width: em($break-3-w); }
    .ie8 .container { width: em($break-3-w); }
    body:before { max-width: em($break-3-w); }
  }

  // Body
  body {
    &:after {
      content: "break-3";
    }
  }

  // Content
  body.no-left-sidebar #content {
    @include span-columns(8);
  }
  body.no-right-sidebar #content {
    @include span-columns(8 omega);
  }

  // Sidebars
  #sidebar-left {
    @include span-columns(4);
  }

  #sidebar-right {
    @include span-columns(4 omega);
  }

}//End break-3

//-------------
//-- BREAK 4 --
//-------------
@include media($break-4) {

  @if $fixed-container {
    .container { max-width: em($break-4-w); }
    body:before { max-width: em($break-4-w); }
  }
  .ie8 .container { width: em($break-4-w); }

  // Body
  body {
    &:after {
      content: "break-4";
    }
  }

  // Content
  body.no-left-sidebar #content {
    @include span-columns(10);
  }
  body.no-right-sidebar #content {
    @include span-columns(10 omega);
  }

  // Sidebars
  #sidebar-left {
    @include span-columns(4.75);
  }

  #sidebar-right {
    @include span-columns(4.75 omega);
  }

}//End break-4
