//------------------------------------------------------------------------------
// DEVELOPMENT.SCSS ************************************************************
// This file contains styles and logic only used during development.
//------------------------------------------------------------------------------

//-----------------------
//-- BREAKPOINT LABELS --
//-----------------------
// Set $show-breakpoint-label to true in variables.scss to show breakpoint labels
@if $show-breakpoint-label {
 body:after {
   display: block;
   position: fixed;
   top: 50px;
   right: 0;
   height: 25px;
   padding: rhythm(0.25) rhythm(0.5);
   color: black;
   @include rgba-bg(red);
 }
 @include media($break-1) {
   body:after {
     @include rgba-bg(orange);
   }
 }
 @include media($break-2) {
   body:after {
     @include rgba-bg(yellow);
   }
 }
 @include media($break-3) {
   body:after {
     @include rgba-bg(teal);
   }
 }
}
