//--------------------------------------------------------------------------------
// COLORBOX.SCSS *****************************************************************
// This file is used to style the colorbox popup
//--------------------------------------------------------------------------------

#cboxOverlay {
  @include opacity(0.5);
  background: $white;
}
#cboxLoadingGraphic {
  background: url('../img/colorbox-loading.gif') no-repeat center center;
}
#cboxLoadingOverlay {
  background: $white;
}
#cboxPrevious,
#cboxNext {
  position: absolute;
  top: 50%;
  width: 35px;
  height: 60px;
  margin-top: -30px;
  text-indent: -9999px;
  @include rgba-bg($black,0.5);

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -18px;
    margin-left: -9px;
  }
  &:hover {
    @include rgba-bg($black,0.75);
  }
}
#cboxPrevious {
  left: 0;

  &:before {
    @include icon(cb-prev);  
  }
}
#cboxNext {
  right: 0;

  &:before {
    @include icon(cb-next);
  }
}
#cboxClose {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 0;
  right: 0;
  text-indent: -9999px;
  @include rgba-bg($black,0.5);

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
    @include icon(cb-close);
  }
  &:hover {
    @include rgba-bg($black,0.75);
  }
}
