//--------------------------------------------------------------------------------
// MENUS.SCSS ********************************************************************
// This file is used to style the different menus of the website. Use the class
// .toggle to make the menu dropdown for mobile.
//--------------------------------------------------------------------------------

//-------------
//-- GENERAL --
//-------------
nav {
  a {
    display: block;
    text-decoration: none;
  }
}

//--------------------
//-- SECONDARY MENU --
//--------------------
.secondary-menu {
  display: none;
}

//-----------------------------------------
//-- SECONDARY MENU DUPLICATE FOR MOBILE --
//-----------------------------------------
.secondary-menu-duplicate {
  // width: 50%;
  // float: right;
  > ul {
    display: none;
    width: 50%;
    float: right;
    position: relative;
    top: em(51px);
    li {
      a {
        display: block;
        padding: em(5px) 0;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &.active,
        &.active-trail {
          font-weight: 700;
        }
      }
    }
  }
}


//---------------
//-- MAIN MENU --
//---------------
.main-menu {

  &.open p.title:after {
    // @include icon(btn-menu-close);
    @include icon(icon-menu-close);
  }

  p.title {
    background: $blue;
    margin: 0;
    @include break-out($gutter/2);
    line-height: em(46px);
    color: $white;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    &:after {
      display: block;
      // @include icon(btn-menu);
      @include icon(icon-menu);
      content: "";
      position: absolute;
      top: 50%;
      right: $gutter/2;
      margin-top: -10px;
    }
  }
  > ul {
    display: none;
    width: 50%;
    float: left;
    li {
      a {
        display: block;
        padding: em(5px) 0;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          text-decoration: underline;
        }
        &.active,
        &.active-trail {
          font-weight: 700;
        }
      }
    }
  }
}

//-----------------
//-- BREADCRUMBS --
//-----------------
#breadcrumb {
  @extend %element-invisible;
  @include font(13);
  line-height: 44px;
  color: $grey;
  span, a {
    display: inline-block;
    line-height: inherit;
    // font-weight: 300;
    color: $grey;
  }
  a span {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $black;
    }
  }
}

//-------------
//-- SUBMENU --
//-------------
.sub-menu {
  width: 100%;
  float: left;
  margin: em(30px) 0 0;
  p.title {
    margin: 0;
    line-height: em(46px);
  }
  > ul {
    width: 100%;
    float: left;
    li {
      border-bottom: 1px solid transparent;
      a {
        display: block;
        background: $brown;
        padding: em(10px);
        color: $white;
        text-decoration: none;
      }
      &:hover {
        a {
          background: $gold;
        }
      }
      &.active,
      &.active-trail {
        a {
          background: $gold;
          font-weight: 700;
        }
      }
    }
  }

  // li {
  //   margin: 0 0 rhythm(0.3);

  //   &.last {
  //     margin-bottom: 0;
  //   }
  // }
  // a {
  //   background: $blue;
  //   color: $white;
  //   padding: rhythm(0.6) rhythm(1);
  //   @include transition(all 0.2s ease-in-out);
  //   text-transform: uppercase;
  // }
  // li.active a, li.active-trail a,
  // a:hover {
  //   background: $blue-light;
  // }
}


//-----------------
//-- FOOTER MENU --
//-----------------
.footer-menu {
  float: left;
  width: 100%;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 rhythm(0.25);

    &.first {
      margin-left: 0;
    }
    &.last {
      margin-right: 0;
    }
  }
  a {
    text-transform: lowercase;
    @include font(18);

    &:hover {
      text-decoration: underline;
    }
  }
}

//-----------------
//-- BOTTOM MENU --
//-----------------
.bottom-menu {
  @include span-columns(2);
  text-align: right;
  margin-top: rhythm(1.2);

  li {
    display: inline-block;
    margin: 0 rhythm(0.25);

    &.first {
      margin-left: 0;
    }
    &.last {
      margin-right: 0;
    }
  }
  a {
    @include font(14);
    color: $white;

    &:hover {
      text-decoration: underline;
    }
  }
}

//*************************
//***** MEDIA QUERIES *****
//*************************

//-------------
//-- BREAK 1 --
//-------------
@include media($break-1) {

  // Sub menu
  .sub-menu {
    > ul {
      li {
        float: left;
        border-right: 1px solid transparent;
      }
    }
  }

  // Footer menu
  .footer-menu {
    width: auto;
    margin-top: rhythm(0.05);
  }

  // Bottom menu
  .bottom-menu {
    @include span-columns(3);
  }

}//End break-1

//-------------
//-- BREAK 2 --
//-------------
@include media($break-2) {

  // Secondary menu
  .secondary-menu {
    float: left;
    display: block;

    ul {
      position: relative;
      width: auto;
    }
    li {
      float: left;
      position: relative;

      &:after {
        content: "";
        width: 1px;
        height: 14px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
        background: $white;
      }
    }
    a {
      color: $white;
      @include font(15);
      line-height: 40px;
      padding: 0 rhythm(0.6);
    }
    li.first a {
      padding-left: 0;
    }
    li.last:after {
      content: none;
    }
    li.last a {
      padding-right: 0;
    }
    li.active a, li.active-trail a,
    a:hover {
      // color: $blue-light;
      color: $gold;
    }
    li.active a, li.active-trail a {
      font-weight: 600;
    }
  }

  // Secondary menu duplicate for mobile
  .secondary-menu-duplicate {
    display: none!important;
  }

  // Main menu
  .main-menu {
    width: 100%;
    float: left;
    margin-top: em(30px);
    p.title {
      display: none;
    }
    > ul {
      display: table!important;
      width: 100%;
      text-align: center;
      li {
        display: table-cell;
        a {
          font-size: em(18px);
        }
        &.active a,
        &.active-trail a {
          font-weight: 700;
        }
      }
    }
  }

  // Breadcrumbs
  #breadcrumb {
    width: 100%;
    float: left;
    margin: rhythm(0.6) 0 rhythm(0.4);
    clip: auto;
    height: auto;
    overflow: auto !important;
    position: relative !important;
  }

  // Submenu
  .sub-menu {
    margin: 0 0 rhythm(0.6);
    > ul {
      li {
        width: 100%;
      }
    }
  }

  // Bottom menu
  .bottom-menu {
    width: auto;
    clear: left;
  }

}//End break-2

//-------------
//-- BREAK 3 --
//-------------
@include media($break-3) {

  // Bottom menu
  // .bottom-menu {
  //   @include span-columns(6);
  // }

}//End break-3
