//------------------------------------------------------------------------------
// PLACEHOLDERS.SCSS ***********************************************************
// This file is used to define global placeholders throughout the project.
//------------------------------------------------------------------------------

//-------------------
//--- PLACEHOLDERS --
//-------------------

// Hidden elements
%element-invisible {
  position: absolute;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

// Toggle
%toggle {
  p.title {
    cursor: pointer;
    margin: 0;
    text-transform: lowercase;
    position: relative;
  }
  ul {
    display: none;
  }
  li {
    display: block;
  }
}

//*************************
//***** MEDIA QUERIES *****
//*************************

//-------------------
//-- BREAK 2 --------
//-------------------
@include media($break-2) {
  // Toggle
  %toggle {
    p.title {
      display: none;
    }
  }
}//End break-2
