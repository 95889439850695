//--------------------------------------------------------------------------------
// MIXINS.SCSS ********************************************************************
// This files contains our own (the AIM) mixins
//--------------------------------------------------------------------------------

//-----------------------------------------
//---- RESET CSS FOR AN UNORDERED LIST ----
//-----------------------------------------
@mixin reset-list() {
  list-style: none;
  margin: 0;
  padding: 0;
}

//------------------------------
//---- OPACITY CROSSBROWSER ----
//------------------------------
@mixin opacity($opacity) {
  // IE 8
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})";
  // Netscape
  -moz-opacity: $opacity;
  // Safari 1.x
  -khtml-opacity: $opacity;
  // Good browsers
  opacity: $opacity;
}

//--------------------------------------
//---- ALTERNATIVE FOR RGBA-PNG() ------
//--------------------------------------
@mixin rgba-bg($color:$black, $opacity:0.7) {
  background: $color;
  background: rgba(red($color), green($color), blue($color), $opacity);
}

//-----------------------------------------------
//---- ALTERNATIVE FOR ADJUST-FONT-SIZE-TO() ----
//-----------------------------------------------
@mixin font($to-size, $lines: 1, $from-size: $base-font-size) {
  @if $to-size == $from-size {
    font-size: em($to-size);
  }
  @else {
    $correction: 1 / ($from-size / $base-font-size);
    font-size: 1em * (($to-size / $from-size) * $correction);
  }
  line-height: 1em * (1 + (1 - ($base-font-size / $base-line-height))) * $lines;
}

//-------------------
//---- HYPHENATE ----
//-------------------
@mixin hyphenate($hyphens: auto, $breaks: normal, $wraps: break-word) { // $hyphens: none | manual | auto || $breaks: normal | break-all | keep-all || $wraps: normal | break-word 
  @include hyphens($hyphens);
  @include word-break($breaks);
  word-wrap: $wraps;
}

//-----------------
//---- HYPHENS ----
//-----------------
@mixin hyphens($value: auto) { // none | manual | auto
  @include prefixer(hyphens, $value, webkit moz ms o spec);
  @include prefixer(hyphenate-character, '\002D', webkit moz ms o spec);
}

//--------------------
//---- WORD BREAK ----
//--------------------
@mixin word-break($value: normal) { // normal | break-all | keep-all
  @include prefixer(word-break, $value, ms spec);
}

//-------------------
//---- BREAK-OUT ----
//-------------------
$allowed-directions: ("left", "right");
@mixin break-out($value: 100%, $direction: default) {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  @if $direction != default {
    @if index($allowed-directions, $direction) {
      margin-#{$direction}: -$value;
      padding-#{$direction}: $value;
    }
    @else {
      @warn "The given direction is not valid."
    }
  }
  @else {
    margin-left: -$value;
    margin-right: -$value;
    padding-left: $value;
    padding-right: $value;
  }
}

//-------------------------
//---- SELECTION COLOR ----
//-------------------------
@mixin selection($background:$highlight, $text:$white) {
  &::selection {
    background-color: $background;
    color: $text;
  }
  // Webkit based browsers
  &::-webkit-selection {
    background-color: $background;
    color: $text;
  }
  // Mozilla based browsers
  &::-moz-selection {
    background-color: $background;
    color: $text;
  }
  // Opera
  &::-o-selection {
    background-color: $background;
    color: $text;
  }
  // Internet Explorer
  &::-ms-selection {
    background-color: $background;
    color: $text;
  }
}

//-------------------------
//---- ICON SHORTHAND -----
//-------------------------
@mixin icon($icon) {
  @extend %icon-#{$icon};
  @extend %icon-#{$icon}-dims;
}
