//--------------------------------------------------------------------------------
// STYLE.SCSS ********************************************************************
// This file is the core of the CSS files. Here the
// different .scss files are being imported.
//--------------------------------------------------------------------------------

// ------------------------
// ------ STANDARD --------
// ------------------------
@charset "UTF-8";

// ------------------------
// ------ LIBRARIES -------
// ------------------------
@import "bourbon"; // Import Bourbon Sass mixins
@import "neat-helpers"; // Import grid helpers before setting visual grid vars

//------------------------
//----- DEFINITIONS ------
//------------------------
@import "variables"; // Import all defined variables (grid, font, colors, etc.)

//------------------------
//----- GRID SYSTEM ------
//------------------------
@import "neat"; // Import neat library after setting variables/functions/mixins
@import "components/neat_overrides"; // Import custom neat functions/mixins

// ------------------------
// ----- COMPONENTS -------
// ------------------------
@import "components/functions";
@import "components/mixins";
@import "components/icon-svg-sprite.scss";

//------------------------
//------ STYLEGUIDE ------
//------------------------
@import "base_include";
@import "forms_include";

//------------------------
//------- PLUGINS --------
//------------------------
@import "plugins/colorbox";

//------------------------
//------- FRONTEND -------
//------------------------
@import "structure";
@import "entities";
@import "blocks";
@import "components/placeholders";
@import "menus";

//------------------------
//--------- CORE ---------
//------------------------
@import "admin";
@import "print";

// ------------------------
// ----- DEVELOPMENT ------
// ------------------------
@import "components/development";
