//------------------------------------------------------------------------------
// ENTITIES.SCSS ***************************************************************
// This file is used to theme all view modes of different entity types.
//------------------------------------------------------------------------------

//-------------
//-- GENERAL --
//-------------
// Image overview
// Use this class on the ul of an overview of images
.images {
  @include reset-list;
  margin-top: rhythm(1.5);

	> li {
		@include span-columns(3 of 6);
		margin-bottom: rhythm(0.6);

    &.even {
      @include omega;
    }
		img {
			display: block;
      width: 100%;
		}
	}
}

// Genaral overview
// Use this class to style an overview of items where every li has 100% width,
// so where all items are displayed under each other
.overview {
  width: 100%;
  float: left;

  > * {
    width: 100%;
    float: left;
    margin-bottom: rhythm(1.5);
  }
}

// Pager
ul.pager {
  width: 100%;
  float: left;
  text-align: center;
  list-style-type: none;
  border-top: 1px solid $blue;
  padding-top: rhythm(0.5);
  margin-bottom: rhythm(0.8);

  li {
    display: inline-block;
    margin: 0 rhythm(0.2) rhythm(0.2);
  }
  a,
  .pager__item--current {
    display: inline-block;
    @include font(18);
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  .pager__item--current {
    font-weight: 700;
  }
}

// SHARELINKS
.sharelinks {
  @extend %element-invisible;
  float: left;
  width: 100%;
  margin: rhythm(0.75) 0;

  a {
    display: inline-block;
    text-indent: -9999px;
    margin: 0 rhythm(0.15);

    &:hover {
      @include opacity(0.75);
    }
  }
  .facebook {
    @include icon(facebook-share);
  }
  .twitter {
    @include icon(twitter-share);
  }
  .linkedin {
    @include icon(linkedin-share);
  }
  .googleplus {
    @include icon(googleplus-share);
  }
  .pinterest {
    @include icon(pinterest-share);
  }
}

// File
.file {
  display: block;
  border: 1px solid $blue;
  color: $grey;
  margin: rhythm(0.5) 0 rhythm(1);
  padding: rhythm(0.5);

  a {
    font-weight: 700;
    text-decoration: none;
    margin: 0 rhythm(0.1);
    @include hyphenate;

    &:hover {
      text-decoration: underline;
    }
  }
}

//----------
//-- NEWS --
//----------
// General overview
.overview__news {
	margin: rhythm(0.5) 0 0;
	p.title {
    @include font(24, 0.9);
    margin-bottom: rhythm(0.15);
    font-weight: 600;

    a {
      color: $blue-light;
			text-decoration: none;
		}
	}
	time {
    display: block;
    @include font(14);
		margin-bottom: rhythm(0.75);
	}
	p:not(.title) {
		margin-bottom: rhythm(0.5);
	}
	img {
		float: left;
		margin: 0 rhythm(0.5) 0 0;
	}
}
// Latest news overview
.overview__news--latest {
  margin-top: rhythm(1);
}

// News detail
.node-type-news {
	h1 {
		margin: 0;
	}
	time {
		display: block;
		margin-bottom: rhythm(0.7);
	}
}

//---------
//-- FAQ --
//---------
.faq-question {
  padding-left: rhythm(1.5);
  position: relative;
  cursor: pointer;
  @include font(16);
  min-height: 30px;
  line-height: 30px;

  &:before {
    @include icon(btn-arrow-right);
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
  }

  &.down:before {
    @include icon(btn-arrow-down);
  }
}

.faq-answer {
  display: none;
  margin-bottom: rhythm(1);
}


// PRODUCTEN
.catalogus__taxonomy-content {
  width: 100%;
  float: left;
  margin: em(30px) 0;
}
.catalogus__content-item {
  width: 100%;
  float: left;
  margin-bottom: em(30px);
  text-decoration: none;
  position: relative;
  img {
    display: block;
  }
  &:hover {
    .product__title {
      background: $black;
    }
  }
}
.product__title {
  background: rgba(0,0,0,0.25);
  padding: em(10px);
  color: $white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

//*************************
//***** MEDIA QUERIES *****
//*************************

//-------------
//-- BREAK 1 --
//-------------
@include media($break-1) {

	// Image overview
	.images {
		> li {

			&.odd, &.even {
        @include span-columns(3 of 9);
			}
      &.third {
        @include omega;
      }
		}
	}

  // PRODUCTEN
  .catalogus__content-item {
    @include span-columns(3);
    &.even {
      @include omega;
    }
  }

}//End break-1

//-------------
//-- BREAK 2 --
//-------------
@include media($break-2) {

	// Pager
	ul.pager {
	  text-align: right;
	  margin-bottom: 0;
	}

  //sharelinks
  .sharelinks {
    width: 100% !important;
    position: relative !important;
    height: auto !important;
    clip: auto;
    overflow: auto !important;
  }

  // File
  .file {
    margin: rhythm(1) 0;
  }

  // Latest news overview
  .overview__news--latest {
    margin: rhythm(1.5) 0 rhythm(0.75);

    > article {
      @include span-columns(4 of 8);

      &.even {
        @include omega;
      }
    }
  }

}//End break-2

//-------------
//-- BREAK 3 --
//-------------
@include media($break-3) {

	// Image overview
	.images {
		> li {
			margin-bottom: rhythm(0.7);
		}
	}

  // PRODUCTEN
  .catalogus__content-item {
    &.odd,
    &.even {
      @include span-columns(3 of 9);
    }
    &.third {
      @include omega;
    }
  }

}//End break-3
